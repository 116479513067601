import {
    addLines,
    cartBuyerIdentityUpdate,
    createCartQuery,
    discountCodesUpdate,
    getCart,
    getCartCost,
    getCartItemsPage,
    removeLines,
    updateLines
} from "~/apollo/queries/carts";
import {AttributeInput, Cart, CartBuyerIdentityInput} from "~/gql/schema";


export const useCart = () => ({
    createCart: (variantId: string, quantity: number = 1, sellerHandle: string, productHandle: string) => {
        return useMutationShopify<{ createCart: { cart: Cart } }>(
            {
                mutation: createCartQuery,
                variables: {
                    input: {
                        lines: [
                            {
                                quantity,
                                merchandiseId: variantId,
                                attributes: [
                                    {
                                        key: 'sellerHandle',
                                        value: sellerHandle
                                    },
                                    {
                                        key: 'productHandle',
                                        value: productHandle
                                    }
                                ]
                            }
                        ],
                        attributes: sellerHandle != null ? [{key: 'seller', value: sellerHandle}] : [],
                        buyerIdentity: {countryCode: useCountry()}
                    }
                }
            }
        )
    },
    async getCart(cartId: string): Promise<Cart | undefined> {
        const {result, fetchMore, loading} = useQueryShopify<{ cart: Cart }>(
            {
                query: getCart,
                variables: {
                    id: cartId,
                    linesCursor: undefined
                }
            }
        )
        while (loading.value) {
            await new Promise(resolve => setTimeout(resolve, 100))
        }

        if (!result.value?.cart) {
            return undefined
        }

        const cart: Cart = result.value.cart
        let pageInfo = result.value.cart.lines.pageInfo

        while (pageInfo.hasNextPage) {
            const {data: moreData} = await fetchMore({
                variables: {linesCursor: pageInfo.endCursor, id: cartId},
                query: getCartItemsPage,
            })

            pageInfo = moreData.cart.lines.pageInfo
            cart.lines.edges = [...cart.lines.edges, ...moreData.cart.lines.edges]
            cart.lines.pageInfo = pageInfo
        }

        return cart
    },
    updateDiscountCodes: (cartId: string, codes: string[]) => {
        return useMutationShopify(
            {
                mutation: discountCodesUpdate,
                variables: {
                    cartId,
                    discountCodes: codes,
                    country: useCountry()
                }
            }
        )
    },
    updateBuyerIdentity: (cartId: string, buyerIdentity: CartBuyerIdentityInput) => {
        return useMutationShopify(
            {
                mutation: cartBuyerIdentityUpdate,
                variables: {
                    cartId, buyerIdentity, country: useCountry()
                }
            }
        )
    },
    addLine: (cartId: string, variantId: string, quantity: number = 1, sellerHandle: string, productHandle: string) => {
        const variables = {
            cartId,
            lines: [
                {
                    merchandiseId: variantId,
                    quantity: quantity,
                    attributes: [
                        {
                            key: 'sellerHandle',
                            value: sellerHandle
                        },
                        {
                            key: 'productHandle',
                            value: productHandle
                        }
                    ]


                }

            ]
        }
        const {mutate} = useMutation(
            addLines,
        )
        return mutate(variables)
    },
    removeLine: (cartId: string, lineId: string) => {
        const variables = {
            cartId,
            lines: [lineId]
        }
        const {mutate} = useMutation(
            removeLines
        )
        return mutate(variables)
    },
    updateLine: (cartId: string, cursor: string, lineInput: {
        quantity: number,
        id: string,
        attributes: AttributeInput[]
    }) => {
        const variables = {
            cartId,
            linesCursor: cursor,
            lines: [lineInput]
        }
        const {mutate} = useMutation(
            updateLines,
        )
        return mutate(variables)
    },
    getCost: (cartId: string) => {
        const {result, loading} = useQueryShopify<{ cart: Cart }>(
            {
                query: getCartCost,
                variables: {
                    id: cartId,
                }
            }
        )
        return result.value?.cart.cost
    }
})